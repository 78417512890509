var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.getTrustPilotStars
    ? _c("simple-trustpilot", {
        attrs: {
          stars: _vm.getTrustPilotStars,
          template: _vm.getTrustPilotTemplate,
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }