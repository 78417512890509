var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.stars
    ? _c(
        "div",
        { staticClass: "simple-trustpilot" },
        [
          _c("zg-trustpilot", { attrs: { stars: _vm.stars } }),
          _vm._v(" "),
          _c("p", { domProps: { innerHTML: _vm._s(_vm.template) } }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }